<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Panel header="Administrar Polizas">
          <Toast />
          <Dialog v-model:visible="documentDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <AccountingPolicyUI :id="id_document" :isModal="true" />
          </Dialog>
        <BasicFormToolbar @refresh="refresh" @list="list" :actions="['refresh', 'list']" />
        <div class="grid">
          <div class="col-12">
                <Fieldset legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <FormCalendar wrapperClass="field col-3" label="Inicio" v-model="entity.initial_date"/>
                    <FormCalendar wrapperClass="field col-3" label="Fin" v-model="entity.final_date"/>
                    <FormDropdown wrapperClass="field col-3" label="Tipo" v-model="entity.id_accounting_policy_type" :options="types" optionLabel="name" optionValue="id"/>
                    <FormDropdown wrapperClass="field col-3" label="Estatus" v-model="entity.status" :options="[{name: 'Todas', value: 0}, {name: 'Registrado', value: 1},{name: 'Afectada', value: 2}]" optionLabel="name" optionValue="value"/>
                  </div> 
                </Fieldset> <br>
                <Fieldset :toggleable="true" legend="Resultados">
                  <BasicDatatable 
                    :exportFilename="'ADMINISTRADOR_POLIZA'"
                    :selectionMode="'single'" 
                    :headers="headers" 
                    :rows="entities" 
                    :rowaction="true"
                    :rowdelete="true"
                    :rowviewDocument="true"
                    @viewDocument="viewDocument"
                    @deleted="cancelar"
                  />
                </Fieldset>
          </div>
        </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { AccountingPolicyType } from "../../../models/contabilidad/AccountingPolicyType";
import {
  HeaderGrid,
  ErrorToast,
Toast,
fillObject,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import Session from "../../../mixins/sessionMixin";
import { AccountingPolicy } from '../../../models/contabilidad/AccountingPolicy';
import AccountingPolicyUI from '../../../pages/Contabilidad/Aplicaciones/AccountingPolicy.vue';
import moment from 'moment';

export default {
  mixins: [Session],
  data() {
    return {
      entity: new AccountingPolicy(this.session),
      parent: null,
      centres: [],
      types: [],
      filters: {},
      entities: [],
      id_document: null,
      documentDialog: null,
      headers: [
        new HeaderGrid("Clave", "key_type"),
        new HeaderGrid("Folio", "id_number"),
        new HeaderGrid("Fecha", "date", { type: 'date' }),
        new HeaderGrid("Tipo", "accounting_policy_type_name"),
        new HeaderGrid("Descripcion", "description"),
        new HeaderGrid("Cargo", "charges_total", { type: 'decimal' , function: 'SUM'}),
        new HeaderGrid("Abono", "payment_total", { type: 'decimal' , function: 'SUM'}),
        new HeaderGrid("Estatus", "status_name", {type: 'component', component: 'Tag'}),
      ],
      loading: false,
    };
  },
  components: { FormCalendar, BasicDatatable,FormDropdown,Loader,BasicFormToolbar, AccountingPolicyUI },
  created() {
    this.entity = new AccountingPolicy(this.session);
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    new() {
      this.entity = {
        branch: null,
        company: null,
        initial_date: new Date(),
        final_date: new Date(),
        status: 0,
        id_accounting_policy_type: 0
      };
    },
    async list() {
      this.loading = true;
      try {
        this.entity.branch = this.session.branch;
        this.entity.company = this.session.company;
        this.entities = await new AccountingPolicy().data({
          branch: this.session.branch,
          company: this.session.company,
          initial_date: moment(this.entity.initial_date).format(),
          final_date: moment(this.entity.final_date).format(),
          id_accounting_policy_type: this.entity.id_accounting_policy_type,
          status: this.entity.status
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async cancelar(entity) {
      this.loading = true;
      this.entity = fillObject(this.entity, entity);
      try {
        if (this.entity.status == 9 || this.entity.status == 2)
        throw "La poliza debe estar en estatus 'REGISTRADA' para realizar esta accion"
      await this.entity.cancelar();
      this.entity.status = 9;
      this.entities.find(x => x.id == this.entity.id).status = 9;
      this.$toast.add(
        new Toast({
          summary: "Poliza",
          detail: "Poliza cancelada con exito",
        })
      );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        this.types = [];
        this.types.push({
          id: 0,
          name: "Todos"
        })
        let types = await new AccountingPolicyType(this.session).all();
        types.forEach(x => this.types.push(x));
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    viewDocument(payload){
      this.id_document = payload.id;
      this.documentDialog = true;
    }
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
